import React from 'react'
import styled from 'styled-components'
import { injectIntl } from 'gatsby-plugin-intl'
import { Container, Col, Row, Small } from '@bootstrap-styled/v4'
import UtrechtSkyline from '../UI/Illustrations/UtrechtSkyline'
import { OutboundLink } from '../Link'
import Icon from '../Icons'
import breakpoints from '../../util/style/breakpoints'

const Wrapper = styled.footer`
  display: flex;
`

const FooterNavbar = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  p {
    margin-bottom: 0;
  }
`
const FooterItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  ${breakpoints.md`
    flex-wrap: unset;
    width: auto;
    align-items: center;
  `};
`

const FooterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  width: 100%;

  &:first-child {
    margin-top: 2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${breakpoints.md`
    margin: 0 1.5rem;
    width: auto;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-right: 0;
      }
  `};
`

const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin: 0 0.75rem;
  }
`

const Footer = ({ withSkyline, intl }) => {
  const date = new Date()
  return (
    <Wrapper>
      <Container>
        {withSkyline && (
          <Row className="justify-content-center mt-5 d-none d-sm-none d-md-flex">
            <Col xs="12" md="8" className="d-flex justify-content-center">
              <UtrechtSkyline />
            </Col>
          </Row>
        )}

        <FooterNavbar className="border-top-2p">
          <Row className="justify-content-center justify-content-md-between align-items-center no-gutters">
            <Small color="muted text-center text-md-left">
              © Copyright Matton Residence {date.getFullYear()}. {intl.formatMessage({ id: 'ALL_RIGHTS_RESERVED' })}.
            </Small>
            <FooterItems>
              <FooterItem>
                <Social>
                  <OutboundLink
                    to="https://www.facebook.com/mattonresidence"
                    target="_blank"
                    from="footer"
                  >
                    <Icon fill="#888888" width="16" name="facebook" />
                  </OutboundLink>
                  <OutboundLink
                    to="https://www.instagram.com/mattonresidence/"
                    target="_blank"
                    from="footer"
                  >
                    <Icon fill="#888888" width="24" name="instagram" />
                  </OutboundLink>
                  <OutboundLink
                    to="https://www.linkedin.com/company/matton-residence/"
                    target="_blank"
                    from="footer"
                  >
                    <Icon fill="#888888" width="24" name="linkedin" />
                  </OutboundLink>
                </Social>
              </FooterItem>
              <FooterItem>
                <Icon fill="#888888" width="84" height="40" name="vbo" />
              </FooterItem>
            </FooterItems>
          </Row>
        </FooterNavbar>
      </Container>
    </Wrapper>
  )
}

export default injectIntl(Footer)

import styled from 'styled-components'
import breakpoints from '../../../util/style/breakpoints'

const Section = styled.section`
  display: flex;
  background-color: ${props => props.theme['$gray-dark']};
  margin-bottom: 3.5rem;

  ${breakpoints.md`
    margin-bottom: 6.5rem;
  `};

  ${breakpoints.xl`
    margin-bottom: 13rem;
  `};

`

export default Section
